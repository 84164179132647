import React, { useState, useEffect } from "react";
import { StaticQuery, graphql, navigate } from "gatsby";
import GatsbyLink from "../GatsbyLink";
import "./SideNavigation.scss";

export const SideNavigationPass = ({
  pageContext: { wordpress_id, wordpress_parent },
  data,
  location,
}) => {
  const pages = data.allWordpressPage.edges;
  const relatedPages = pages.filter(
    (i) => i.node.wordpress_parent === wordpress_parent
  );
  const childPages = pages.filter(
    (i) => i.node.wordpress_parent === wordpress_id
  );
  const parentPage = pages.filter((i) => i.node.id === wordpress_parent);
  const loopPages = wordpress_parent === 0 ? childPages : relatedPages;

  // Logic for small devices
  const handleSelectChange = (path) => {
    if (!path) return null;
    navigate(path);
  };

  if (loopPages) {
    return (
      <div className="side-navigation">
        <div className="large-only">
          <span className="typography--h3 typography-h3--underlined">
            Filter by:
          </span>
          <ul>
            {loopPages &&
              loopPages.map((page, index) => (
                <li key={index}>
                  <GatsbyLink
                    to={page.node.link}
                    decode={true}
                    className={
                      page.node.link.includes(location?.pathname)
                        ? "active"
                        : ""
                    }
                  >
                    {page.node.title}
                  </GatsbyLink>
                </li>
              ))}
          </ul>
        </div>
        <div className="small-only">
          <select
            className="filter-select"
            name="filter"
            id="filter"
            value={location?.pathname}
            onChange={(e) => handleSelectChange(e.target.value)}
          >
            {loopPages &&
              loopPages.map((page, index) => (
                <option key={index} value={page.node.link}>
                  {page.node.title}
                </option>
              ))}
          </select>
        </div>
      </div>
    );
  }
  return null;
};

export const SideNavigation = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressPage(sort: { order: ASC, fields: menu_order }) {
            edges {
              node {
                id: wordpress_id
                title
                slug
                link
                wordpress_parent
              }
            }
          }
        }
      `}
      render={(data) => <SideNavigationPass {...props} data={data} />}
    />
  );
};
