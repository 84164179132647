import React from "react";
import "./AsxGraphs.scss";

export const AsxGraphs = ({ heading, graphs }) => {
  return (
    <section className="asx-graphs">
      <div className="wrap">
        {heading && (
          <div className="heading">
            <h2>{heading}</h2>
          </div>
        )}
        <div className="graphs">
          {Object.keys(graphs).length > 0 &&
            graphs?.map((graph, index) => {
              return (
                <div className="col" key={index}>
                  <h3 className="graph-heading">{graph?.graphTitle}</h3>
                  <iframe
                    // style={backgroundColor:transparent, padding:0px, padding-bottom: 0}
                    src={graph?.graphUrl}
                    width="100%"
                    height="465px"
                    scrolling="no"
                    frameBorder="0"
                    style={{
                      backgroundColor: "transparent",
                      padding: 0,
                    }}
                  ></iframe>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
