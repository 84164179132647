import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

/**
 * Report Component that accepts an item object
 * @param {object} apiItem Contains headline information, including a date, pages and link to a pdf.
 * @param {string} apiItem.HeadlineText Text to appear against the report
 * @param {string} apiItem.date Report date
 * @param {string} apiItem.pdfLink Link to download/viewing of the report
 * @returns ReportItem component.
 */
export const ReportItem = ({ apiItem = {} }) => {
  let { HeadlineText = "", date, pdfLink = "#" } = apiItem;
  // We are expecting a date of the form "YYYYMMDD", e.g. "20120929"
  let dateYear = date.slice(0, 4);
  let dateMonth = date.slice(4, 6) - 1; // ensures our date is indexed correctly and is not 1 month ahead
  let dateDay = date.slice(date.length - 2, date.length);
  let formattedDate = format(
    new Date(dateYear, dateMonth, dateDay),
    "dd MMM yyyy"
  );

  return (
    <a className="pdfItem" href={pdfLink} target="_blank" rel="noreferrer">
      <h3>{HeadlineText}</h3>
      <span className="pdfItem__date">{formattedDate}</span>
    </a>
  );
};
