import React from "react";
import "./HomepageHeader.scss";
import { ImagePass } from "../../Image/ImagePass";
import HomePageImageQuery from "../../Image/HomePageImageQuery";
import GatsbyLink from "../../GatsbyLink";
import Carousel from "../../Carousel";
import { Breadcrumbs } from "../../Breadcrumbs";
import { decodeEntities } from "../../../utils/helpers";

export const HomepageHeader = ({ header, subHeader, video }) => {
  return (
    <section className="homepage-header wrap">
      <div className="wrap">
        <div className="content">
          <h1 className="title">{header}</h1>
          {subHeader && <p className="sub-title">{subHeader}</p>}
        </div>

        {video && (
          <video
            autoPlay
            muted
            loop
            src={video.url}
            className="background-video"
          ></video>
        )}
      </div>
    </section>
  );
};
