import React, { useState } from "react";
import _ from "lodash";
import Accordion from "../../Accordion";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import "./StaffProfiles.scss";

export const StaffProfiles = ({ staffProfiles, title }) => {
  const [activeStaff, setActiveStaff] = useState();

  const handleProfileClick = (individualIndex) => {
    if (individualIndex === activeStaff) {
      setActiveStaff();
    } else {
      setActiveStaff(individualIndex);
    }
  };

  return (
    <section className="staff-profiles">
      <div className="wrap">
        {title && <h2 className="typography-h2--underlined">{title}</h2>}
        <div className="profiles">
          {Object.keys(staffProfiles).length > 0 &&
            staffProfiles?.map((individual, index) => {
              return (
                <div
                  className={`col ${activeStaff === index ? "active" : ""}`}
                  key={index}
                  onClick={() => handleProfileClick(index)}
                >
                  <div className="profile-header">
                    <div className="image">
                      <ImagePass src={individual.image} />
                    </div>
                    <div className="content">
                      <h3 className="individual-heading">{individual.name} </h3>
                      <RenderContent
                        content={individual.qualifications}
                        className="individual-qualifications"
                      />
                      <RenderContent content={individual.position} />
                      <RenderContent
                        content={individual.profile}
                        className="individual-profile-large"
                      />
                    </div>
                  </div>
                  <RenderContent
                    content={individual.profile}
                    className="individual-profile"
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
