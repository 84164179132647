import React, { useState, useEffect } from "react";
import { useAnnouncementData } from "../../../hooks";
import { ReportItem } from "./ReportItem";
import { SideNavigation } from "../../SideNavigation";
import "./AsxAnnouncements.scss";

/**
 * We need a range of years to get the content from
 * Pass in an array of years, for that length create the autopopulated content.
 * Either have manual input or automatic population
 */

/**
 *
 * @param {string} asxReportCategory Category used for fetching data
 * @param {string} className Custom class name.
 * @returns Container holding the ASX report information.
 */

export const AsxAnnouncements = ({ asxReportCategory, pageContext }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [dataReady, setDataReady] = useState(false);

  const handleButtonFocus = (event, index) => {
    event.preventDefault();
    setCurrentPage(index + 1);
  };

  const announcementData = useAnnouncementData(
    "2020",
    "false",
    currentPage,
    500,
    asxReportCategory,
    setDataReady
  );

  // If we receive no headlines don't return an empty year group.
  if (!announcementData?.headlines?.length) return null;

  return (
    <div className="asx-annoucements">
      <div className="asx-annoucements-wrap">
        <div className="navigation">
          <SideNavigation pageContext={pageContext} location={location} />
        </div>
        <div className="annoucements-list">
          <div className="pdfList">
            {dataReady &&
            announcementData.totalHeadlines &&
            announcementData?.headlines ? (
              announcementData.headlines.map((headline) => (
                <ReportItem key={headline.headlineid} apiItem={headline} />
              ))
            ) : (
              <span>Loading...</span>
            )}
          </div>
          {/* pagination */}
          <div className="page__number">
            {Array.from(
              { length: announcementData.totalPages },
              (_key, index) =>
                announcementData.totalPages > 1 && (
                  <button
                    key={index}
                    onFocus={(e) => handleButtonFocus(e, index)}
                    onClick={(e) => handleButtonFocus(e, index)}
                    className={
                      currentPage === index + 1 ? "active--button" : ""
                    }
                    disabled={currentPage === index + 1 ? true : false}
                  >
                    {index + 1}
                  </button>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AsxAnnouncements.defaultProps = {
  asxReportCategory: "all",
};
