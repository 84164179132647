import React from "react";
import OnVisible from "react-on-visible";
import { withPrefix } from "gatsby";
import { RenderShortcode } from "../../RenderShortcode";
import { SideNavigation } from "../../SideNavigation";
import { Helmet } from "react-helmet";
import { ImagePass } from "../../Image/ImagePass";
import {
  GravityForm,
  gravityFormExtractor,
} from "../../../components/gravity-form";
import { useAllGravityForms } from "../../../hooks";
import cx from "classnames";
import "./FlexibleBlock.scss";
import { decodeEntities } from "../../../utils/helpers";
import Accordion from "../../Accordion";
import LazyLoad from "react-lazy-load";

export const FlexibleBlock = (props) => {
  const getComponent = (item, key, pageContext) => {
    const {
      backgroundImagePosition,
      backgroundImageSize,
      backgroundStyle,
      content_type,
      contentVideo,
      iframe,
      section_content,
      content_background_image,
      icons,
      iconsIntro,
      iconsCenter,
      concertinaTitle,
      concertina,
      outputStructuredData,
      form,
    } = item;
    const isSecondImage = key === 1;
    const isMobileImage = key === "mobile";

    // Get all of our Gravity Forms
    const gravityFormsData = useAllGravityForms();
    const formId = parseInt(form) || 0;

    const formData = gravityFormExtractor(formId, gravityFormsData);

    //FAQ Strcutured Data
    let faqSchema = {};
    if (concertina) {
      const FAQ = concertina.map((faq) => {
        return {
          "@type": "Question",
          name: faq.title,
          acceptedAnswer: {
            "@type": "Answer",
            text: faq.content,
          },
        };
      });
      faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [FAQ],
      };
    }
    return (
      <div
        className={`col${content_type ? ` ${content_type}` : ""}${
          isMobileImage && content_type === "image" ? " mobile" : ""
        }${isSecondImage && content_type === "image" ? " desktop" : ""}${
          backgroundStyle && backgroundStyle != "" && backgroundStyle != "none"
            ? " " + backgroundStyle
            : ""
        }`}
        key={key}
      >
        {content_type == "video" && (
          <div className="inner-content video-block">
            <LazyLoad>
              <div dangerouslySetInnerHTML={{ __html: contentVideo }} />
            </LazyLoad>
            <div className="loading" />
          </div>
        )}
        {content_type == "iframe" && (
          <div className="inner-content iframe-block">
            <LazyLoad>
              <iframe
                src={decodeEntities(iframe)}
                frameBorder="0"
                allowFullScreen
              />
            </LazyLoad>
            <div className="loading" />
          </div>
        )}
        {content_type == "image" && content_background_image && (
          <div className="inner-content image-block">
            <ImagePass src={content_background_image} />
          </div>
        )}
        {content_type == "text" && (
          <div className="inner-content text-block">
            <RenderShortcode content={section_content} />
          </div>
        )}
        {content_type == "menu" && (
          <div className="inner-content text-block menu-block">
            <SideNavigation pageContext={pageContext} />
            <RenderShortcode content={section_content} />
          </div>
        )}
        {content_type == "icons" && (
          <div
            className={`inner-content icons-block${
              iconsCenter ? " center" : ""
            }`}
          >
            {iconsIntro && <RenderShortcode content={iconsIntro} />}
            <ul>
              {icons.map((item, index) => (
                <li key={index}>
                  <ImagePass className="inner-image" src={item.icon} />
                  {item.iconTitle && <p>{decodeEntities(item.iconTitle)}</p>}
                </li>
              ))}
            </ul>
          </div>
        )}
        {content_type == "concertina" && concertina && (
          <>
            {outputStructuredData && (
              <Helmet>
                <script type="application/ld+json">
                  {JSON.stringify(faqSchema)}
                </script>
              </Helmet>
            )}
            <div className="inner-content text-block">
              {concertinaTitle && (
                <h2 className="concertina-title">{concertinaTitle}</h2>
              )}
              <Accordion concertina={concertina} />
            </div>
          </>
        )}
        {content_type == "form" && gravityFormsData && (
          <>
            {gravityFormsData && (
              <div className="gravity-form-wrapper">
                <div className="gravity-form">
                  <GravityForm
                    formData={formData}
                    displayDescription={true}
                    dataLayerEvent={"form_submit_success"}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };
  //console.log('props:',props)
  const {
    columns = [],
    section,
    wrapWidth = wrapWidth ? wrapWidth : "wrap",
    backgroundStyle,
    backgroundImage,
    backgroundImagePosition,
    backgroundImageSize,
    sectionTitle,
    sectionClassName,
    pageContext,
    sectionId,
  } = props;
  const sectionClassPad = section ? " section" : "";
  const column_number = ["zero", "one", "two", "three", "four"];
  const sectionClass =
    sectionClassName && sectionClassName != "none"
      ? cx("flexible", sectionClassName)
      : `flexible block`;
  const globalClasses = backgroundStyle
    ? cx(sectionClass, backgroundStyle)
    : sectionClass;
  const containsMenu =
    (columns[0] && columns[0].content_type === "menu") ||
    (columns[1] && columns[1].content_type === "menu") ||
    (columns[2] && columns[2].content_type === "menu")
      ? true
      : false;

  return (
    <section className={globalClasses} id={sectionId ? sectionId : null}>
      <div
        className={`${column_number[columns.length]}-col${
          wrapWidth === "none" || wrapWidth == "" ? "" : ` ${wrapWidth}`
        }${sectionClassPad}${containsMenu ? " contains-menu" : ""}`}
      >
        <div className={`inner`}>
          {
            //Mobile image column
            columns && columns[1] && columns[1].content_type === "image"
              ? getComponent(columns[1], "mobile")
              : null
          }
          {columns.map((item, index) => getComponent(item, index, pageContext))}
        </div>
      </div>
      {backgroundImage && (
        <div className="section-background">
          <ImagePass src={backgroundImage} />
        </div>
      )}
    </section>
  );
};
