import React, { useState } from "react";
import GatsbyLink from "../../GatsbyLink";
import { ImagePass } from "../../Image/ImagePass";
import "./ServiceList.scss";

export const ServiceList = ({ serviceList, darkBackground }) => {
  return (
    <section
      className={`service-list ${darkBackground ? "service-list--dark" : ""}`}
    >
      <div className="wrap">
        <div className="inner">
          {Object.keys(serviceList).length > 0 &&
            serviceList?.map((listItem, index) => {
              return (
                <GatsbyLink
                  className="list-item"
                  to={listItem.link.url}
                  key={index}
                >
                  <h2 className="heading">{listItem?.heading}</h2>
                  <span className="button--light">{listItem?.link?.title}</span>
                  <div className="background">
                    <ImagePass
                      src={listItem?.background}
                      className="background-image"
                    />
                  </div>
                </GatsbyLink>
              );
            })}
        </div>
      </div>
    </section>
  );
};
