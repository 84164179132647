import React, { Component, Fragment } from 'react';
import { decodeEntities } from '../../utils/helpers';
import RenderContent from '../RenderContent'
import './Accordion.scss';

const isClient = typeof window !== 'undefined';
const Item = (props) => {
  const { item, openNavKey, toggleSubNav, keyId } = props;
  //console.log('concertina:',props)
  const { title, content } = item;
  const isopen = openNavKey === keyId;
  return (
    <div key={keyId}>
      <div onClick={event => toggleSubNav(event, keyId)} className={`trigger ${isopen ? 'open' : 'closed'}`}>
        {decodeEntities(title)}
        <div className="icon"></div>
      </div>
      <div dangerouslySetInnerHTML={{__html: content}} className={`content ${isopen ? 'open' : 'closed'}`} />
    </div>
  );
}
export default class Accordion extends Component {
  state = { openNavKey: null };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  render() {
    const { concertina, active } = this.props;
    const { openNavKey } = this.state;
    return (
      <div className={`accordion ${active ? 'active' : ''}`}>

        {concertina && concertina.map((col, index) => (
          <Item key={index} keyId={index} item={col} toggleSubNav={this.toggleSubNav} openNavKey={openNavKey} />
        ))}

      </div>
    );
  }
}
